///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available variables





//-------------------------
//    #Colors
//    Global default settings
//	  for colors
//-------------------------


$black: #434a54;
$buttonFeedback: #F96F57;
$titleBlack: #000E2B;
$borderColor: #ececec;
$borderColorBlue: #c5ecfb;
$white: #fff;
$orange: #ee7421;
$gray: #a9b3bf;
$blue: #4fc0e8;
$dealColor: #f2fafc;
$green: #8cc155;
$lightOrange: #fdd053;
$smallColor: #aab2bd;
$red: #ed5565;
$darkBlack: #000;
$editorBackground: #fcfcfc;
$lightGreen: #ace8de;
$lightGray:#dcdee2;
$darkerWhite: #f5f7fa;
$tagBorder: #efefef;
$tagBackground: #434a54;
$dealBlockColor: #f2f2f2;
$investmentTitle : #ccd1d9;
$investmentBorder : #e6e9ed;
$addCompanySmallTitle : #656d78;
$errorBackground : #fdf0f1;
$pendingBackground : #f9f9f9;
$acceptBackground : #f3fafc;
$green: #ace9db;
$tableGray: #f6f7fb;
$colorCheck: #a0d468;
$founderBrand: #56ccbd;
$yellow: #ffce54;
$overGreen: #f9fcf6;
$lightOrange : #fff0e5;
$dealIconColor: #ececec;
$approvedColor: #4a89dc;
$grayColor: #abacb1;
$darkerBlue: #f1fbfe;
$darkerWhiteDO: #e5e9ef;
$smallBlockBorder: #d2d4d8;
$positive: #a0d468;
$dark-gray: #aab2bd;
$light-gray: #808080;
$layerBackground: #e6e9f0;
$layerBorder: #ccd0d9;
$dealCircle: #ebf3fc;
$dealCircleText: #4b8cd8;
$brand: #1f7be8;
$brandHover: #125bb1;
$saveButton: #B1C4E3;
$dropdownColor: #f7f8fc;
$buttonColor: #666666;
$inputColor: #CDD0D7;
$darkerBrand: #004699;
$darkerGreen: #3b7100;
$darkerRed: #cb0015;
$grayLight: #a9b2bd;
$founderDarkerBrand: #390153;
$founderDarkerBrandHover: #6f296c;
$founders-sign-color: #ebf6f7;
$orange-pink: #fc6e51;
$borderWhite: #e7e9ed;
$resultsBackground: #f5f7fa;
$pdfPageBorder: #525659;
$pdfScrollBar: #f0f0f1;
$pdfScrollBarThumb: #c0c0c1;
$pdfDownloadHover: #e8f1fc;
$dark-brand: #185fb2;
$orange-badge: #f6bb42;
$red-badge: #da4353;
$light-blue: #d7e9ff;
$disabled-grey: #e0e0e0;
$selectedLightBlue: #e8f0fa;
$blue-badge: #78aae6;
$azul: #1f7be8;
$charcoal-grey: #434a54;
$poisonous-green: #34f4bb;
$light-black: #333333;

// New color variables that should be used
$color-blue-main: #1f7be8;
$color-blue-light: #5d9fee;
$color-blue-dark: #125bb1;
$color-blue-light-hover: #ecf3fc;
$color-blue-dark-hover: #d2e5fa;
$color-white-grey: #ddd;

$color-green-main: #a0d468;
$color-green-light: #c1e39d;
$color-green-light-2: #31bc78;
$color-green-dark: #7fc137;
$color-green-dark-1: #6fae2c;
$color-green-dark-2: #7dd0dc;
$color-green-main-2: #56ccbd;
$color-green-main-3: #e2f5f7;

$color-red-main: #da4353;
$color-red-light: #e57c87;
$color-red-dark: #b52433;

$color-yellow-main: #f6bb42;
$color-yellow-light: #f9d283;
$color-yellow-dark: #e9a00b;

$color-white: #ffffff;
$color-white-1: #f1f1f1;
$color-white-2: #fafbfd;
$color-white-3: #dae0e8;

$color-grey-1: #f5f7fa;
$color-grey-2: #e7e9ed;
$color-grey-3: #ccd1d9;
$color-grey-4: #a9b2bd;
$color-grey-5: #656d78;
$color-grey-6: #434a54;
$color-grey-7: #eeeeee;
$color-grey-8: #e2e2e2;
$color-grey-9: #f8f8f8;
$color-grey-10: #959ea8;
$color-grey-11: #f0f0f0;
$color-grey-12: #e5e9ef;
$color-grey-13: #56585A;
$color-grey-14: #d2d4d8;
$color-grey-15: #e6e9ed;
$color-grey-16: #dee1e7;
$color-grey-17: #9a9a9a;
$color-grey-18: #dedede;
$color-grey-19: #dbe0e7;
$color-grey-20: #99a1ab;
$color-grey-21: #727a82;
$color-grey-22: #acadb2;
$color-grey-23: #aaaaaa;
$color-grey-24: #555555;
$color-grey-25: #aab8c9;
$color-grey-26: #cbcfd5;

$color-brown-1: #666666;

$color-file-excel: #0d904f;
$color-file-word: #0d5aa7;
$color-file-pdf: #ff1919;
$color-file-powerpoint: #ce8735;
$color-file-image: #deb40e;

//Compact design colors
$color-green-compact-hover: #629b25;
$color-yellow-compact-main: #f6bb42;
$color-yellow-compact-hover: #e9a00b;
$color-red-compact-main: #dd5564;
$color-red-compact-hover: #b52433;
$color-compact-background: #f9fafc;

//ProRaise colors
$color-purple-main: #390153;
$color-purple-main-lighter: #57017e;
$color-purple-main-bright: #bf43da;
$color-purple-main-pale: #744D86;
$color-cyan-secondary: #56ccbd;
$color-cyan-secondary-darker: #35b1a0;
$color-cyan-secondary-pale: #c3e8eb;
$color-cyan-light: #e2f5f7;

//Network status colors
$color-network-invited: #f6bb42;
$color-network-no-deals: #bf43da;
$color-network-contacts: #aab8c9;
$color-network-matched: #a0d468;

$toBigPhone: 639px;
$bigPhone: 640px;
$phone: 767px;
$tablet: 768px;
$toTabletLandscape: 959px;
$tabletLandscape: 960px;
$toDesktop: 1024px;
$desktop: 1025px;
$toSmallLaptop: 1279px;
$smallLaptop: 1280px;
$ipadPro: 1366px;
$toLaptop: 1599px;
$laptop: 1600px;
$toBigScreen: 1919px;
$bigScreen: 1920px;
$hugeScreen: 2048px;
//-------------------------
//--------MIXIN BREAKPOINTS
//-------------------------
// EXAMPLE
//   @include breakpoint (phone) {
//       width: 40%;
//   }
//
@mixin breakpoint($point) {
    // from 2048px
    @if $point==hugeScreen {
        @media (min-width: $hugeScreen) {
            @content;
        }
    }
    // from 1920px
    @if $point==bigScreen {
        @media (min-width: $bigScreen) {
            @content;
        }
    }
    // from 1025px
    @else if $point==desktop {
        @media (min-width: $desktop) {
            @content;
        }
    }
    // from 1025px to 1279px
    @else if $point==miniLaptop {
        @media (min-width: $desktop) and (max-width: $toSmallLaptop) {
            @content;
        }
    }
    // from 1280px to 1919px
    @else if $point==laptop {
        @media (min-width: $smallLaptop) and (max-width: $toBigScreen) {
            @content;
        }
    }
    // from 1600px to 1919px
    @else if $point==bigLaptop {
        @media (min-width: $laptop) and (max-width: $toBigScreen) {
            @content;
        }
    }
    // from 1280px to 1599px
    @else if $point==smallLaptop {
        @media (min-width: $smallLaptop) and (max-width: $toLaptop) {
            @content;
        }
    }
    // from 960px to 1279px
    @else if $point==tabletLandscape {
        @media (min-width: $tabletLandscape) and (max-width: $toSmallLaptop) {
            @content;
        }
    }
    // 1366px and orientation landscape (this is only for iPadPro)
    @else if $point==ipadProLandscape {
        @media only screen and (min-device-width: $ipadPro) and (max-device-width: $ipadPro) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
            @content;
        }
    }
    // to 1024px
    @else if $point==toDesktop {
        @media (max-width: $toDesktop) {
            @content;
        }
    }
    // from 320px to 959px (all mobile and tablet devices)
    @else if $point==devices {
        @media (min-width: 320px) and (max-width: $toTabletLandscape) {
            @content;
        }
    }
    // from 320px to 600px  (mobile and tablet devices)
    @else if $point==smallDevices {
        @media (min-width: 320px) and (max-width: 600px) {
            @content;
        }
    }
    // from 639px to 959px
    @else if $point==tabletPortrait {
        @media (min-width: $toBigPhone) and (max-width: $toTabletLandscape) {
            @content;
        }
    }
    // from 640px to 767px
    @else if $point==smallTablet {
        @media (min-width: $bigPhone) and (max-width: $phone) {
            @content;
        }
    }
    // from 768px to 1024px
    @else if $point==tablet {
        @media (min-width: $tablet) and (max-width: $toDesktop) {
            @content;
        }
    }
    // to 640px
    @else if $point==toBigPhone {
        @media (max-width: $bigPhone) {
            @content;
        }
    }
    // to 767px (all mobile devices)
    @else if $point==toPhone {
        @media (max-width: $phone) {
            @content;
        }
    }
    // to 850px (all mobile devices landscape)
    @else if $point==toPhoneLandscape {
        @media (max-width: 1023px) and (orientation: landscape) {
            @content;
        }
    }

/*************************** Use these breakpoints. Don't use old ones. ******************************/

    // from 0px to 450px
    @else if $point==smallMobileScreen {
        @media (min-width: 0px) and (max-width: 450px) {
            @content;
        }
    }
    // from 0px to 599px
    @else if $point==mobileScreen {
        @media (min-width: 0px) and (max-width: 599px) {
            @content;
        }
    }
    // from 0px to 600px
    @else if $point==mobileScreen2 {
        @media (min-width: 0px) and (max-width: 600px) {
            @content;
        }
    }
    // from 0px to 800px
    @else if $point==allMobileScreen {
        @media (min-width: 0px) and (max-width: 900px) {
            @content;
        }
    }
    // mobiles up to 599px height in landscape orientation
    @else if $point==mobileScreenLandscape {
        @media (max-height: 599px) and (max-width: 830px) and (orientation: landscape) {
            @content;
        }
    }
    // from 600px to 899px
    @else if $point==tabletScreen {
        @media (min-width: 600px) and (max-width: 899px) {
            @content;
        }
    }
    // from 610px to 900px
    @else if $point==tabletScreen2 {
        @media (min-width: 610px) and (max-width: 900px) {
            @content;
        }
    }
    // from 600px to 1366px in landscape orientation
    @else if $point==tabletScreenLandscape {
        @media (min-width: 600px) and (max-width: 1366px) and (min-height: 600px) and (max-height:1024px) and (orientation: landscape) {
            @content;
        }
    }
    // from 900px to 1199px
    @else if $point==smallScreen {
        @media (min-width: 900px) and (max-width: 1199px) {
            @content;
        }
    }
    // from 1200px to 1799px
    @else if $point==mediumScreen {
        @media (min-width: 1200px) and (max-width: 1799px) {
            @content;
        }
    }
    // from 1800px
    @else if $point==largeScreen {
        @media (min-width: 1800px) {
            @content;
        }
    }
}

@mixin multiple-media($media...) {
    @each $query in $media {
        @include breakpoint($query) {
            @content
        }
    }
}

// Retina mixin
@mixin retina {
    @media only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2) { @content; }
}

// Windows 150% zoom mixin
@mixin windowsZoom {
    @media only screen and (max-width: $bigScreen) {
        @media only screen and (min-resolution: 120dpi) {
            @media only screen and (max-device-pixel-ratio: 1.99),
            only screen and (-webkit-max-device-pixel-ratio: 1.99),
            only screen and (max--moz-device-pixel-ratio: 1.99) {
                @content;
            }
        }
    }
}

@import '../scss/settings/settings.colors';

/* Common START */
body {
    height: 100vh;
    background-color: #ffffff;
    margin: 0;
    padding: 100px 100px 0;
    box-sizing: border-box;
    font-family: Montserrat, sans-serif;
    color: #000000;
    overflow-x: hidden;
}
input, button, p, a, span, h1, h2, h3, h4, h5 {
    font-family: inherit;
}
@media (min-width: 1025px){
    html{
        font-size: 0.53734551vw;
    }
}
a {
    text-decoration: none;
}
body img {
    max-width: 100%;
    border: none;
    outline: none;
}
body ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
body ul li {
    list-style-type: none;
}
* {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
/* Let's get this party started */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: $brand;
    -webkit-box-shadow: inset 0 0 2px $brand;
}
::-webkit-scrollbar-thumb:window-inactive {
    background: $brand;
}
.width{
    width:100%;
    margin-top: -3.5rem;
}

.layout.vertical {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.flex {
    -ms-flex: 1 1 0.000000001px;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0.000000001px;
    flex-basis: 0.000000001px;
}
.flex-auto {
    -ms-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
}
.flex-none {
    -ms-flex: none;
    -webkit-flex: none;
    flex: none;
}
.self-start {
    -ms-align-self: flex-start;
    -webkit-align-self: flex-start;
    align-self: flex-start;
}
.button {
    font-family: Montserrat, sans-serif;
    min-width: 140px;
    width: auto;
    height: 40px;
    margin: 0;
    padding: 9px 32px;
    border: solid 2px #ffffff;
    background: none;
    color: inherit;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    box-sizing: border-box;
    outline: none;
    transition: all 0.2s linear;
}
a.button {
    display: block;
}
.linkedin-icon {
    width: 24px;
    height: 22px;
}
[data-target] {
    cursor: pointer;
}
.divider {
    background-color: $brand;
}
.text-uppercase {
    text-transform: uppercase;
}
.check-mark {
    width: 15px;
    height: 15px;
    margin-top: -1px;
    position: absolute;
    left: 0;
}
.check-mark:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid #000000;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.circle {
    width: 5px;
    height: 5px;
    background: #000000;
    border: 1px solid #000000;
    border-radius: 50%;
    position: absolute;
    left: 0;
}
.list-title {
    margin-top: 0;
    margin-bottom: 35px;
}
.text-gray {
    color: #a9b2bd;
}
@keyframes opac {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/* Common END */

/* Header START */
.header {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    background-color: #ffffff;
}
.logo {
    width: 140px;
    height: 25px;
    cursor: pointer;
}
/* Header END */

/* Section START */
.section {
    margin: 0 140px 100px;
}
.login-arrow{
    font-size: 10px;
    margin-right: 5px;
}

.section > * {
    margin-bottom: 100px;
}
.section > *:last-child {
    margin-bottom: 0;
}
.tabs-menu {
    padding: 0;
    margin: 0;
    position: relative;
}
.tab-menu-item {
    font-size: 11px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    padding: 0 0 10px;
    margin: 0 35px;
    cursor: pointer;
    display: inline-block;
}
.tab-menu-item.active {
    color: $brand;
}
.tabs-menu .tab-menu-item-underline {
    height: 2px;
    margin: 0;
    background: $brand;
    border: none;
    transition: .3s ease-in-out;
}
.tabs-content-container {
    width: 100%;
    position: relative;
}
body *[data-tab-content] {
    width: 100%;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
body *[data-tab-content].active {
    visibility: visible;
    opacity: 1;
    height: auto;
    transition: all 0.5s linear;
    position: relative;
}

/* Section END */

/* Page START */
.icon-action, .close-action {
    width: 100px;
    height: 100px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0.8;
    transition: opacity 0.4s linear;
    cursor: pointer;
}
.icon-action:hover, .close-action:hover {
    opacity: 1;
}
.icon, .close-icon {
    width: 14px;
    height: 14px;
    font-size: 18px;
    line-height: 14px;
    color: #000000;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    opacity: inherit;
}
.close-icon {
    width: 30px;
    height: 30px;
    font-size: 30px;
    line-height: 30px;
}
.page {
    width: 70.8888%;
    overflow: auto;
    background: #ffffff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 11;
    transform: translate(150%, 0);
    transition: transform 0.4s linear;
}
.page.active {
    transform: translate(0, 0);
    transition: transform 0.4s linear;
}
.overflow-hidden {
    overflow: hidden;
}
.page-header {
    position: relative;
    color: #ffffff;
}
.page-header .title {
    margin: 0;
    font-weight: 300;
    position: absolute;
    left: 100px;
    bottom: 65px;
}
.page-footer {
    height: 140px;
    padding-left: 100px;
    padding-right: 100px;
    h2 {
        margin: 0;
    }
    .button {
        min-width: 120px;
    }
}

.close-action-line{
    border-bottom: 1px solid #d2d4d8;
}
.position{
    .uppercase{
        text-transform: uppercase;
        font-weight: bold;
        display: block;
        font-size: 1.3rem;
        margin-bottom: 1rem;
    }
    ul{
        list-style-type: initial;
        padding-left: 2rem;
    }
    li{
        font-style: normal;
        font-stretch: normal;
        line-height: 1.85;
        letter-spacing: normal;
        text-align: justify;
        font-size: 1.3rem;
        list-style-type: initial;
    }
}
.small-layer{
    z-index: 12;
}
/* Media queries START */
@media (max-width: 1024px) {
    .login__back {
        padding: 1rem;
        font-size: 14px;
    }
    .width{
        margin-top: -20px;
    }
    .header{
        height: auto;
        padding: 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .login__message{
        left: 1rem;
    }
    .login__message span {
        position: absolute;
        top: 0.6rem;
        right: 0.5rem;
        cursor: pointer;
        font-size: 15px;
    }
    .form-group input{
        font-size: 16px;
    }
}
@media (min-width: 768px) and (max-width: 1024px){
    .underline-placeholder {
        width: 70%;
    }
    .header{
        padding: 20px !important;
    }
}

/* Media queries START */
@media (max-width: 1600px) {
    .section {
        margin-left: 60px;
        margin-right: 60px;
    }
}
@media (max-width: 1024px) {
    body {
        padding-top: 56px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .button {
        min-width: 103px;
        height: 35px;
        font-size: 12px;
        line-height: 16px;
    }
    .check-mark {
        width: 11px;
        height: 8px;
    }
    .check-mark:after {
        width: 3px;
        height: 9px;
    }
    .icon-action, .close-action {
        width: 50px;
        height: 50px !important;
    }
    .close-icon {
        width: 22px;
        height: 22px;
        font-size: 22px;
        line-height: 22px;
    }
    .header {
        min-height: inherit;
        padding: 20px 0;
    }
    .header .logo {
        width: 84px;
        height: 15px;
    }
    .section {
        margin-left: 20px;
        margin-right: 20px;
    }
    .tabs-menu {
        position: relative;
        display: flex;
        align-items: center;
    }
    .tabs-menu .tab-menu-item-underline {
        position: absolute;
        bottom: 0;
    }
    .footer {
        margin-bottom: 17px;
    }
    .footer > p {
        margin-bottom: 20px;
    }
    .footer > p {
        font-size: 10px;
        line-height: 2;
    }
    .page {
        width: 90%;
    }
    .sign-in-page .page-content {
        padding-left: 60px;
        padding-right: 60px;
        margin-top: 80px;
    }
    .page-header .title {
        left: 60px;
        bottom: 80px;
    }
    .page-content .content {
        margin-bottom: 40px;
    }
    .page-footer {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        box-sizing: border-box;
        text-align: center;
    }
}
@media (max-width: 767px) {

    body {
        padding-left: 10px;
        padding-right: 10px;
    }
    .section, .section > * {
        margin-bottom: 50px;
    }
    .page {
        width: 100%;
    }
    .sign-in-page .page-content {
        padding-left: 20px;
        padding-right: 20px;
    }
    .page-header .title {
        left: 20px;
        bottom: 40px;
    }
    .underline-placeholder{
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
@media (max-width: 320px) {
    .underline-placeholder {
        width: 35%;
    }
}
@media (max-width: 260px) {
    .section {
        width: 100%;
    }
}
/* Media queries END */

.button-link{
    text-transform: none;
    border: 0;
    height: auto;
    min-width: auto;
    padding: 0;
    text-align: inherit;
    font-size: 13px;
    font-weight: 400;
}
.button-link:hover{
    background-color: transparent;
    color: $darkerBrand;
}
.security-page .list-unstyled li{
    position: absolute;
    bottom: -11.5rem;
    right: 0;
    color: #ed5565;
    font-size: 1.1rem;
}
@media (min-width: 1025px){
    .login-arrow{
        font-size: 1rem;
        margin-right: 0.5rem;
    }
    .page-content .title{
        font-size: 3rem;
    }
    .icon, .close-icon{
        width: 1.4rem;
        height: 1.4rem;
        font-size: 1.8rem;
        line-height: 1.4rem;
    }

    body{
        padding: 10rem 10rem 0;
    }
    .security-page{
        padding: 0 !important;
    }

    .page .page-header .title{
        text-shadow: 0 2px 4px rgba(0,0,0,0.40);
    }
    .check-mark{
        width: 1.5rem;
        height: 1.5rem;
    }
    .check-mark:after{
        width: .5rem;
        height: 1rem;
        border-width: 0 .3rem .3rem 0;
    }
    .button{
        min-width: 14rem;
        height: 4rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
        padding: 0.9rem 3.2rem;
    }
    .logo{
        width: 14rem;
        height: 2.5rem;
    }
    .menu ul li, .menu ul li a{
        font-size: 1.3rem;
    }
    .header{
        height: auto;
        padding: 3.5rem 10rem;
        flex-wrap: wrap;
    }
    .footer > p{
        font-size: 1.1rem;
        margin-bottom: 3rem;

    }

    .close-icon{
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
        line-height: 3rem;
    }
    .page-header .title{
        left: 10rem;
    }
    .page-content{
        padding-left: 10rem;
        padding-right: 10rem;
        margin-top: 5rem;
        margin-bottom: 10rem;
        .content{
            margin-bottom: 5rem;
        }
    }
    .icon-action, .close-action{
        width: 10rem;
        height: 10rem;
    }
    .tab-menu-item{
        font-size: 1.2rem;
    }
    .page-footer{
        padding-left: 10rem;
        padding-right: 10rem;
        height: 14rem;
    }
    .page-footer .button{
        min-width: 12rem;
    }
    .button-link{
        text-transform: none;
        border: 0;
        height: auto;
        min-width: auto;
        padding: 0;
        text-align: inherit;
        font-size: 1.3rem;
        font-weight: 400;
    }
    .sign-in-page{
        width: 68rem;
    }
    .sign-in-page .page-content{
        margin-top: 14rem;
        padding-left: 8rem;
        padding-right: 8rem;
        min-height: calc(100% - 38rem);
    }
    .sign-in-page .tab-menu-item{
        padding: 1.1rem 1.5rem;
        letter-spacing: 0.05rem;
    }
    .sign-in-page .tabs-content-container{
        margin-top: 3.3rem;
    }
    .form-group{
        margin-bottom: 1.8rem;
    }
    .form-group label{
        font-size: 1.1rem;
        margin-bottom: 1.1rem;
    }
    .form-group input{
        padding: 1.7rem 2rem;
        font-size: 1.3rem;
    }
    .form-group .forgot-password{
        margin-top: 1rem;
        font-size: 1.1rem;
        padding-right: 0.2rem;
    }
    .form-actions{
        margin-top: 3.5rem;
    }
    .form-actions .button{
        min-width: 14rem;
        padding: 1.5rem 3rem;
        line-height: 1.3rem;
        font-size: 1.1rem;
    }
    .login__back span{
        margin-right: .5rem;
    }
    .request-invite-error{
        height: 2rem;
        font-size: 1.1rem;
        margin-top: .5rem;
    }
    .login__message {
        font-size: 1.1rem;
    }
}
.do-spinner {
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
    position: absolute;
    margin: 0;
    top: 5px;
    right: 0;
    display: none;
}
.do-spinner.showLoader{
    display: block;
}
.do-spinner > div {
    background-color: #434a54;
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.do-spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.do-spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.do-spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.do-spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
    20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }  20% {
           transform: scaleY(1.0);
           -webkit-transform: scaleY(1.0);
       }
}
@media (min-width: 1025px){
    .do-spinner {
        margin: 10rem auto;
        width: 5rem;
        height: 4rem;
        text-align: center;
        font-size: 1rem;
        position: absolute;
        margin: 0;
        top: 0.5rem;
        right: 0;
    }

    .do-spinner > div {
        width: .6rem;
    }
}

@media screen and (min-width:768px) and (max-width:1024px) {
    .sign-in-page .page-content ul.tabs-menu {
        display: block;
        .underline-placeholder {
            width: 100%;
        }
    }
}
@media screen and (max-width:320px) {
    .sign-in-page .page-content ul.tabs-menu {
        .underline-placeholder {
            width: 100%;
        }
    }
}
