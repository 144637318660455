/* Common START */
body {
  height: 100vh;
  background-color: #ffffff;
  margin: 0;
  padding: 100px 100px 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
  color: #000000;
  overflow-x: hidden;
}

input, button, p, a, span, h1, h2, h3, h4, h5 {
  font-family: inherit;
}

@media (min-width: 1025px) {
  html {
    font-size: 0.53734551vw;
  }
}

a {
  text-decoration: none;
}

body img {
  max-width: 100%;
  border: none;
  outline: none;
}

body ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

body ul li {
  list-style-type: none;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px transparent;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #1f7be8;
  -webkit-box-shadow: inset 0 0 2px #1f7be8;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #1f7be8;
}

.width {
  width: 100%;
  margin-top: -3.5rem;
}

.layout.vertical {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex {
  -ms-flex: 1 1 0.000000001px;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0.000000001px;
  flex-basis: 0.000000001px;
}

.flex-auto {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

.flex-none {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}

.self-start {
  -ms-align-self: flex-start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.button {
  font-family: Montserrat, sans-serif;
  min-width: 140px;
  width: auto;
  height: 40px;
  margin: 0;
  padding: 9px 32px;
  border: solid 2px #ffffff;
  background: none;
  color: inherit;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
  outline: none;
  transition: all 0.2s linear;
}

a.button {
  display: block;
}

.linkedin-icon {
  width: 24px;
  height: 22px;
}

[data-target] {
  cursor: pointer;
}

.divider {
  background-color: #1f7be8;
}

.text-uppercase {
  text-transform: uppercase;
}

.check-mark {
  width: 15px;
  height: 15px;
  margin-top: -1px;
  position: absolute;
  left: 0;
}

.check-mark:after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid #000000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.circle {
  width: 5px;
  height: 5px;
  background: #000000;
  border: 1px solid #000000;
  border-radius: 50%;
  position: absolute;
  left: 0;
}

.list-title {
  margin-top: 0;
  margin-bottom: 35px;
}

.text-gray {
  color: #a9b2bd;
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Common END */
/* Header START */
.header {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.logo {
  width: 140px;
  height: 25px;
  cursor: pointer;
}

/* Header END */
/* Section START */
.section {
  margin: 0 140px 100px;
}

.login-arrow {
  font-size: 10px;
  margin-right: 5px;
}

.section > * {
  margin-bottom: 100px;
}

.section > *:last-child {
  margin-bottom: 0;
}

.tabs-menu {
  padding: 0;
  margin: 0;
  position: relative;
}

.tab-menu-item {
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 0 0 10px;
  margin: 0 35px;
  cursor: pointer;
  display: inline-block;
}

.tab-menu-item.active {
  color: #1f7be8;
}

.tabs-menu .tab-menu-item-underline {
  height: 2px;
  margin: 0;
  background: #1f7be8;
  border: none;
  transition: .3s ease-in-out;
}

.tabs-content-container {
  width: 100%;
  position: relative;
}

body *[data-tab-content] {
  width: 100%;
  visibility: hidden;
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

body *[data-tab-content].active {
  visibility: visible;
  opacity: 1;
  height: auto;
  transition: all 0.5s linear;
  position: relative;
}

/* Section END */
/* Page START */
.icon-action, .close-action {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.4s linear;
  cursor: pointer;
}

.icon-action:hover, .close-action:hover {
  opacity: 1;
}

.icon, .close-icon {
  width: 14px;
  height: 14px;
  font-size: 18px;
  line-height: 14px;
  color: #000000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
  opacity: inherit;
}

.close-icon {
  width: 30px;
  height: 30px;
  font-size: 30px;
  line-height: 30px;
}

.page {
  width: 70.8888%;
  overflow: auto;
  background: #ffffff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  transform: translate(150%, 0);
  transition: transform 0.4s linear;
}

.page.active {
  transform: translate(0, 0);
  transition: transform 0.4s linear;
}

.overflow-hidden {
  overflow: hidden;
}

.page-header {
  position: relative;
  color: #ffffff;
}

.page-header .title {
  margin: 0;
  font-weight: 300;
  position: absolute;
  left: 100px;
  bottom: 65px;
}

.page-footer {
  height: 140px;
  padding-left: 100px;
  padding-right: 100px;
}

.page-footer h2 {
  margin: 0;
}

.page-footer .button {
  min-width: 120px;
}

.close-action-line {
  border-bottom: 1px solid #d2d4d8;
}

.position .uppercase {
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.position ul {
  list-style-type: initial;
  padding-left: 2rem;
}

.position li {
  font-style: normal;
  font-stretch: normal;
  line-height: 1.85;
  letter-spacing: normal;
  text-align: justify;
  font-size: 1.3rem;
  list-style-type: initial;
}

.small-layer {
  z-index: 12;
}

/* Media queries START */
@media (max-width: 1024px) {
  .login__back {
    padding: 1rem;
    font-size: 14px;
  }
  .width {
    margin-top: -20px;
  }
  .header {
    height: auto;
    padding: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .login__message {
    left: 1rem;
  }
  .login__message span {
    position: absolute;
    top: 0.6rem;
    right: 0.5rem;
    cursor: pointer;
    font-size: 15px;
  }
  .form-group input {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .underline-placeholder {
    width: 70%;
  }
  .header {
    padding: 20px !important;
  }
}

/* Media queries START */
@media (max-width: 1600px) {
  .section {
    margin-left: 60px;
    margin-right: 60px;
  }
}

@media (max-width: 1024px) {
  body {
    padding-top: 56px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .button {
    min-width: 103px;
    height: 35px;
    font-size: 12px;
    line-height: 16px;
  }
  .check-mark {
    width: 11px;
    height: 8px;
  }
  .check-mark:after {
    width: 3px;
    height: 9px;
  }
  .icon-action, .close-action {
    width: 50px;
    height: 50px !important;
  }
  .close-icon {
    width: 22px;
    height: 22px;
    font-size: 22px;
    line-height: 22px;
  }
  .header {
    min-height: inherit;
    padding: 20px 0;
  }
  .header .logo {
    width: 84px;
    height: 15px;
  }
  .section {
    margin-left: 20px;
    margin-right: 20px;
  }
  .tabs-menu {
    position: relative;
    display: flex;
    align-items: center;
  }
  .tabs-menu .tab-menu-item-underline {
    position: absolute;
    bottom: 0;
  }
  .footer {
    margin-bottom: 17px;
  }
  .footer > p {
    margin-bottom: 20px;
  }
  .footer > p {
    font-size: 10px;
    line-height: 2;
  }
  .page {
    width: 90%;
  }
  .sign-in-page .page-content {
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 80px;
  }
  .page-header .title {
    left: 60px;
    bottom: 80px;
  }
  .page-content .content {
    margin-bottom: 40px;
  }
  .page-footer {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    box-sizing: border-box;
    text-align: center;
  }
}

@media (max-width: 767px) {
  body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .section, .section > * {
    margin-bottom: 50px;
  }
  .page {
    width: 100%;
  }
  .sign-in-page .page-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .page-header .title {
    left: 20px;
    bottom: 40px;
  }
  .underline-placeholder {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

@media (max-width: 320px) {
  .underline-placeholder {
    width: 35%;
  }
}

@media (max-width: 260px) {
  .section {
    width: 100%;
  }
}

/* Media queries END */
.button-link {
  text-transform: none;
  border: 0;
  height: auto;
  min-width: auto;
  padding: 0;
  text-align: inherit;
  font-size: 13px;
  font-weight: 400;
}

.button-link:hover {
  background-color: transparent;
  color: #004699;
}

.security-page .list-unstyled li {
  position: absolute;
  bottom: -11.5rem;
  right: 0;
  color: #ed5565;
  font-size: 1.1rem;
}

@media (min-width: 1025px) {
  .login-arrow {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
  .page-content .title {
    font-size: 3rem;
  }
  .icon, .close-icon {
    width: 1.4rem;
    height: 1.4rem;
    font-size: 1.8rem;
    line-height: 1.4rem;
  }
  body {
    padding: 10rem 10rem 0;
  }
  .security-page {
    padding: 0 !important;
  }
  .page .page-header .title {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }
  .check-mark {
    width: 1.5rem;
    height: 1.5rem;
  }
  .check-mark:after {
    width: .5rem;
    height: 1rem;
    border-width: 0 .3rem .3rem 0;
  }
  .button {
    min-width: 14rem;
    height: 4rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: 0.9rem 3.2rem;
  }
  .logo {
    width: 14rem;
    height: 2.5rem;
  }
  .menu ul li, .menu ul li a {
    font-size: 1.3rem;
  }
  .header {
    height: auto;
    padding: 3.5rem 10rem;
    flex-wrap: wrap;
  }
  .footer > p {
    font-size: 1.1rem;
    margin-bottom: 3rem;
  }
  .close-icon {
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
    line-height: 3rem;
  }
  .page-header .title {
    left: 10rem;
  }
  .page-content {
    padding-left: 10rem;
    padding-right: 10rem;
    margin-top: 5rem;
    margin-bottom: 10rem;
  }
  .page-content .content {
    margin-bottom: 5rem;
  }
  .icon-action, .close-action {
    width: 10rem;
    height: 10rem;
  }
  .tab-menu-item {
    font-size: 1.2rem;
  }
  .page-footer {
    padding-left: 10rem;
    padding-right: 10rem;
    height: 14rem;
  }
  .page-footer .button {
    min-width: 12rem;
  }
  .button-link {
    text-transform: none;
    border: 0;
    height: auto;
    min-width: auto;
    padding: 0;
    text-align: inherit;
    font-size: 1.3rem;
    font-weight: 400;
  }
  .sign-in-page {
    width: 68rem;
  }
  .sign-in-page .page-content {
    margin-top: 14rem;
    padding-left: 8rem;
    padding-right: 8rem;
    min-height: calc(100% - 38rem);
  }
  .sign-in-page .tab-menu-item {
    padding: 1.1rem 1.5rem;
    letter-spacing: 0.05rem;
  }
  .sign-in-page .tabs-content-container {
    margin-top: 3.3rem;
  }
  .form-group {
    margin-bottom: 1.8rem;
  }
  .form-group label {
    font-size: 1.1rem;
    margin-bottom: 1.1rem;
  }
  .form-group input {
    padding: 1.7rem 2rem;
    font-size: 1.3rem;
  }
  .form-group .forgot-password {
    margin-top: 1rem;
    font-size: 1.1rem;
    padding-right: 0.2rem;
  }
  .form-actions {
    margin-top: 3.5rem;
  }
  .form-actions .button {
    min-width: 14rem;
    padding: 1.5rem 3rem;
    line-height: 1.3rem;
    font-size: 1.1rem;
  }
  .login__back span {
    margin-right: .5rem;
  }
  .request-invite-error {
    height: 2rem;
    font-size: 1.1rem;
    margin-top: .5rem;
  }
  .login__message {
    font-size: 1.1rem;
  }
}

.do-spinner {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
  position: absolute;
  margin: 0;
  top: 5px;
  right: 0;
  display: none;
}

.do-spinner.showLoader {
  display: block;
}

.do-spinner > div {
  background-color: #434a54;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.do-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.do-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.do-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.do-spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

@media (min-width: 1025px) {
  .do-spinner {
    margin: 10rem auto;
    width: 5rem;
    height: 4rem;
    text-align: center;
    font-size: 1rem;
    position: absolute;
    margin: 0;
    top: 0.5rem;
    right: 0;
  }
  .do-spinner > div {
    width: .6rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sign-in-page .page-content ul.tabs-menu {
    display: block;
  }
  .sign-in-page .page-content ul.tabs-menu .underline-placeholder {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .sign-in-page .page-content ul.tabs-menu .underline-placeholder {
    width: 100%;
  }
}
